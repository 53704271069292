<template>
  <div class="wrap">
    <div class="container">
      <AiBoxMgrTop class="top"/>
      <AiBoxMgrTable class="table"/>
      <AiBoxMgrTail class="footer"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AiBoxMgrTop from '@/components/SystemSetting/aiBoxMgr/Top.vue'
import AiBoxMgrTable from '@/components/SystemSetting/aiBoxMgr/Table.vue'
import AiBoxMgrTail from '@/components/SystemSetting/aiBoxMgr/Tail.vue'

export default {
  name: 'AiBoxMgr',
  components: { AiBoxMgrTop, AiBoxMgrTable, AiBoxMgrTail },
  data() {
    return {
      timerId: null
    }
  },
  computed: {
    ...mapState('setting/aiboxMgr', ['aiBoxes'])
  },
  methods: {
    ...mapActions('setting/aiboxMgr', ['getAiBoxes', 'getUserList']),
    async fetchData() {
      await this.getAiBoxes()
      await this.getUserList() // 查詢 task 使用
      // console.log(`[fetchData]`, new Date())
    },
    async createTimeout() {
      if (!this.timerId) {
        this.timerId = setTimeout(async () => {
          await this.fetchData()

          this.cancelTimeout()
          await this.createTimeout()
        }, 5000)
      }
    },
    cancelTimeout() {
      if (this.timerId) {
        clearTimeout(this.timerId)
        this.timerId = null
      }
    }
  },
  async mounted() {
    await this.fetchData()
    await this.createTimeout()
  },
  beforeDestroy() {
    this.cancelTimeout()
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap {
  width: 100%;
  height: 100%;
  padding: px2rem(24) px2rem(42) 0;
  background-color: #282942;
  color: #ffffff;
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    // background-color: #00f;

    .top {}

    .table {
      flex-grow: 1;
      height: calc(100% - 90px - 116px);
    }
    .footer {
      display: flex;
      // flex-direction: column;
      justify-content: flex-end;
    }
  }
}
</style>
