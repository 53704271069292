<template>
  <div class="aibox-mgr-table">
    <div class="header">
      <div
        class="col"
        v-for="(col, idx) in colNameList"
        @click="onSort(col.key)"
        :key="`${col.key}${idx}`"
      >
        <div
          class="col-content"
          :style="{ cursor: showSort ? 'pointer' : 'unset' }"
        >
          <div class="name">{{ $t(col.str) }}</div>
          <!-- <div class="sort" v-if="showSort"></div> -->
          <img v-if="showSort" class="sort" :class="getSortCss(col.key)" src="@/assets/icons/Drop.svg" alt="">
        </div>
      </div>
      <div class="col delete"></div>
    </div>
    <div class="content">
      <div v-if="aiBoxes" class="content-body">
        <div v-for="(aiBox, idx) in sortedAiBoxed" class="row" :key="idx">
          <div
            class="row-block"
            :class="{
              abnormal:
                !getAiBoxTaskState(aiBox.tasks) ||
                getUptimeColor(
                  aiBox.updatedTime,
                  aiBox.timeSinceLastUpdated
                ) === red
            }"
            @click.stop="onShowAiBox(idx)"
          >
            <div class="row-col">{{ aiBox.name }}</div>
            <div class="row-col">{{ aiBox.ip }}</div>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              placement="right"
              :visible-arrow="false"
              :content="aiBox.udid"
            >
              <div class="row-col">
                <div class="udid">{{ aiBox.udid }}</div>
              </div>
            </el-tooltip>
            <div class="row-col state" v-if="getAiBoxTaskState(aiBox.tasks)">
              <img class="state-icon" src="@/assets/icons/success-check.svg" />
              <div>{{ $t('setting_aibox_state_normal') }}</div>
            </div>
            <div class="row-col state" v-else>
              <img class="state-icon" src="@/assets/icons/warn-icon.svg" />
              <div>{{ $t('setting_aibox_state_abnormal') }}</div>
            </div>
            <!-- <div class="row-col">{{ aiBox.temperature + '°C' }}</div> -->
            <div class="row-col"><DonutChart :value="aiBox.cpuUsage" /></div>
            <div class="row-col"><DonutChart :value="aiBox.gpuUsage" /></div>
            <div class="row-col">
              <DonutChart
                :value="getIntPercent(aiBox.totalMamory, aiBox.availableMemory)"
                :free="aiBox.availableMemory"
                :total="aiBox.totalMamory"
              />
            </div>
            <div class="row-col">
              <DonutChart
                :value="getIntPercent(aiBox.totalSpace, aiBox.freeSpace)"
                :free="aiBox.freeSpace"
                :total="aiBox.totalSpace"
              />
            </div>
            <div class="row-col recognition">
              <div class="lpr" v-if="aiBox.lprCapability > 0">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="$t('setting_aibox_type_lpr')"
                >
                <img src="@/assets/icons/car.svg" />
                </el-tooltip>
                <div>{{ aiBox.lprUsedResouces }}</div>
                <div>/</div>
                <div>{{ aiBox.lprCapability }}</div>
              </div>
              <div class="or" v-if="aiBox.orCapability > 0">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="$t('setting_aibox_type_or')"
                >
                  <img src="@/assets/icons/object.svg" />
                </el-tooltip>
                <div>{{ aiBox.orUsedResouces }}</div>
                <div>/</div>
                <div>{{ aiBox.orCapability }}</div>
              </div>
              <!-- <div class="fr" v-if="aiBox.frCapability > 0">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="$t('setting_aibox_type_fr')"
                >
                  <img src="@/assets/icons/object.svg" />
                </el-tooltip>
                <div>{{ aiBox.frUsedResouces }}</div>
                <div>/</div>
                <div>{{ aiBox.frCapability }}</div>
              </div> -->
            </div>
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              placement="right"
              :visible-arrow="false"
              :content="formatTimeNoSec(aiBox.updatedTime)"
            >
              <div
                class="row-col ago"
                :style="{
                  color: getUptimeColor(
                    aiBox.updatedTime,
                    aiBox.timeSinceLastUpdated
                  )
                }"
              >
                {{ timeAgo(aiBox.updatedTime, aiBox.timeSinceLastUpdated) }}
                <!-- {{ aiBox.timeSinceLastUpdated }} -->
              </div>
            </el-tooltip>
            <div class="row-col delete">
              <!-- 因為 AiBox 是自己連上線, 所以沒有辦法真的 刪除 AiBox... -->
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                placement="top"
                :visible-arrow="false"
                :content="deleteAiBoxTooltip(aiBox) /*$t('delete')*/"
              >
                <button
                  class=""
                  :disabled="disabledDeleteAiBox(aiBox)"
                  :class="{ abnormal: !getAiBoxTaskState(aiBox.tasks) }"
                  @click.stop="onShowDeleteAiBox(idx)"
                >
                  <img src="@/assets/icons/TrashCan.svg" alt="">
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PortalAiBoxTask
      v-if="showPortalAiBoxTask"
      :subAiBoxes="filteredAiBoxes"
      :aiBox="filteredAiBoxes[currAiBoxIdx]"
      @close="onCloseTaskPortal"
      @scroll="onScrollAiBox"
      @deleteTask="onShowDeleteAiBoxTask"
    />
    <PortalAiBoxDelete
      v-if="showPortalAiBoxDelete"
      :title="$t('setting_aibox_delete_title')"
      :content="
        delPortal.target === euDelTarget.aiBox
          ? $t('setting_aibox_delete_aibox')
          : $t('setting_aibox_delete_aibox_task')
      "
      :portal="delPortal"
      @close="onCloseDeletePortal"
      @confirm="onConfirmPortalAiBoxDelete"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { apiCheckStatus/*, apiErrorMsg*/, apiDeleteAiBox, apiDeleteAiBoxTask } from '@/api'
import { timeAgo, /*diffSecs,*/ sleep, formatTimeNoSec } from '@/utils/lib'
import DonutChart from '@/components/AiBox/base/DonutChart.vue'
import PortalAiBoxTask from '@/components/SystemSetting/aiBoxMgr/PortalAiBoxTask.vue'
import PortalAiBoxDelete from '@/components/SystemSetting/aiBoxMgr/PortalAiBoxDelete.vue'

const euSortLevel = {
  desc: -1,
  none: 0,
  asc: 1
}

export const euDelTarget = {
  aiBox: 0,
  task: 1
}

const colNameList = [
  { key: 'name', str: 'setting_aibox_table_name' },
  { key: 'ip', str: 'setting_aibox_table_ip' },
  { key: 'udid', str: 'setting_aibox_table_udid' },
  { key: 'status', str: 'setting_aibox_table_status' },
  // { key: 'temperature', str: 'setting_aibox_table_temp' },
  { key: 'cpu', str: 'setting_aibox_table_cpu' },
  { key: 'gpu', str: 'setting_aibox_table_gpu' },
  { key: 'mem', str: 'setting_aibox_table_mem' },
  { key: 'hd', str: 'setting_aibox_table_hd' },
  { key: 'recognition', str: 'setting_aibox_table_recognition_src' },
  { key: 'updatedTime', str: 'setting_aibox_table_updated_time' }
]

export default {
  name: 'AiBoxMgrTable',
  components: { DonutChart, PortalAiBoxTask, PortalAiBoxDelete },
  data() {
    return {
      green: '#64D848',
      orange: '#D8A848',
      red: '#F94144',
      colNameList,
      euSortLevel,
      euDelTarget,
      sort: {
        key: '',
        level: euSortLevel.none
      },

      showPortalAiBoxTask: false,
      showPortalAiBoxDelete: false,
      // currAiBox: null,
      currAiBoxIdx: -1,
      delPortal: {
        target: euDelTarget.aiBox,
        data: null
      }
    }
  },
  mounted() {},
  computed: {
    ...mapState('userinfo', ['accountPortal']),
    ...mapState('setting/aiboxMgr', [
      'aiBoxes',
      'aiBoxFilterText',
      'ipFilterText',
      'currPage',
      'userList'
    ]),
    ...mapGetters('setting/aiboxMgr', ['filteredAiBoxes']),
    showSort() {
      return this.filteredAiBoxes.length > 1
    },
    sortedAiBoxed() {
      if (this.sort.key) {
        let { key, level } = this.sort

        const sortLogic = (level, aVal, bVal) => {
          if (level === euSortLevel.desc) {
            return aVal < bVal ? 1 : -1
          } else if (level === euSortLevel.asc) {
            return aVal > bVal ? 1 : -1
          }
          return 0
        }

        // 所有排序, 都要以 更新時間 為基礎
        const target = JSON.parse(JSON.stringify(this.filteredAiBoxes)).sort(
          (a, b) => {
            return sortLogic(level, a.updatedTime, b.updatedTime)
          }
        )
        if (key === 'updatedTime') return target

        if (key === 'status') {
          return target.sort((a, b) => {
            const aVal = a.tasks.filter((task) => task.status !== 0).length
            const bVal = b.tasks.filter((task) => task.status !== 0).length

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'cpu') {
          key = 'cpuUsage'
        } else if (key === 'gpu') {
          key = 'gpuUsage'
        } else if (key === 'mem') {
          // 用 已使用百分比 做排序
          return target.sort((a, b) => {
            const aVal = (a.totalMamory - a.availableMemory) / a.totalMamory
            const bVal = (b.totalMamory - b.availableMemory) / b.totalMamory

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'hd') {
          // 用 已使用百分比 做排序
          return target.sort((a, b) => {
            const aVal = (a.totalSpace - a.freeSpace) / a.totalSpace
            const bVal = (b.totalSpace - b.freeSpace) / b.totalSpace

            return sortLogic(level, aVal, bVal)
          })
        } else if (key === 'recognition') {
          // 用 剩餘辨識總和 做排序
          return target.sort((a, b) => {
            const aVal = (a.lprCapability - a.lprUsedResouces) + (a.orCapability - a.orUsedResouces)
            const bVal = (b.lprCapability - b.lprUsedResouces) + (b.orCapability - b.orUsedResouces)

            return sortLogic(level, aVal, bVal)
          })
        }

        return target.sort(function (a, b) {
          return sortLogic(level, a[key], b[key])
        })
      }
      return this.filteredAiBoxes
    }
  },
  watch: {
    currPage(/*nVal, oVal*/) {
      this.sort = {
        key: '',
        level: euSortLevel.none
      }
    }
  },
  methods: {
    ...mapActions('setting/aiboxMgr', ['getAiBoxes']),
    ...mapActions(['getUserList']),
    ...mapMutations('userinfo', ['updateAccountPortal']),
    timeAgo,
    getAiBoxTaskState(tasks) {
      let state = true
      const abnormals = tasks.filter((task) => task.status !== 0)

      if (abnormals.length > 0) {
        state = false
      }

      return state
    },
    getIntPercent(total, free) {
      let usage = Math.ceil(((total - free) * 100) / total)

      return usage
    },
    getTempColor(temp) {
      if (!temp) {
        return this.green
      }

      if (temp > 70 && temp <= 90) {
        return this.orange
      } else if (temp > 90) {
        return this.red
      }

      return this.green
    },
    getUptimeColor(updatedTime, timeSinceLastUpdated) {
      if (!updatedTime) {
        return this.green
      }

      const diff = timeSinceLastUpdated //diffSecs(updatedTime)
      if (diff > 30 && diff <= 60) {
        return this.orange
      } else if (diff > 60) {
        return this.red
      }

      return this.green
    },
    /*formatBytes(bytes, separator = "") {
      // ref: https://gist.github.com/lanqy/5193417
      if(!bytes) { return 0 }

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 0

      // Math.log2(8) = 3
      let i = parseInt(Math.floor(Math.log2(bytes) / Math.log2(1024)), 10)

      // 最大換算單位 為 'TB'
      if (i >= sizes.length) {i = (sizes.length - 1)}

      if (i === 0) {return `${bytes}${separator}${sizes[i]}`}
      // x**n = Math.pow(x,n)
      return `${(bytes / (1024 ** i)).toFixed(1)}${separator}${sizes[i]}`
    },*/
    formatTimeNoSec,
    getSortCss(name) {
      const { key, level } = this.sort
      const self = name === key
      const css = {
        'sort-asc': self && level === euSortLevel.asc,
        'sort-desc': self && level === euSortLevel.desc
      }

      return css
    },
    disabledDeleteAiBox(aiBox) {
      const usedResouces = aiBox.lprUsedResouces + aiBox.orUsedResouces // + aiBox.frUsedResouces
      return usedResouces > 0 ? true : false
    },
    deleteAiBoxTooltip(aiBox) {
      const hasResouces = this.disabledDeleteAiBox(aiBox)
      return hasResouces ? this.$t('setting_aibox_delete_aibox_hint') : this.$t('delete')
    },
    // 事件
    onSort(name) {
      const { key, level } = this.sort

      if (name === key) {
        if (level === euSortLevel.none) {
          this.sort.level = euSortLevel.desc
        } else if (level === euSortLevel.desc) {
          this.sort.level = euSortLevel.asc
        } else {
          this.sort.level = euSortLevel.none
        }
      } else {
        this.sort = {
          key: name,
          level: euSortLevel.desc
        }
      }
    },
    onShowDeleteAiBox(aiBoxIdx) {
      this.currAiBoxIdx = aiBoxIdx;
      const { name , udid } = { ...this.filteredAiBoxes[aiBoxIdx] }
      this.delPortal = {
        target: euDelTarget.aiBox,
        data: { name, udid, aiBoxIdx }
      }

      this.showPortalAiBoxDelete = true
      this.updateAccountPortal('aibox-delete')
    },
    onShowAiBox(aiBoxIdx) {
      // console.log(`[onShowAiBox] aiBoxIdx:`, aiBoxIdx)
      this.currAiBoxIdx = aiBoxIdx

      this.showPortalAiBoxTask = true
      this.updateAccountPortal('aibox-task')
    },
    onShowDeleteAiBoxTask(aiboxUdid, taskIdx) {
      try {
        const currAiBox = this.filteredAiBoxes.find((_b) => _b.udid === aiboxUdid)

        if (!currAiBox) return

        const { name, udid, tasks } = currAiBox
        const currTask = tasks[taskIdx]

        // Get device
        const account = this.userList.find(
          (user) => user.index === Number(currTask.sourceId)
        )
        const device = ( account ) ? `${account.video.title}(${account.id})` : null
        const { ai, status } = currTask

        this.delPortal = {
          target: euDelTarget.task,
          data: { name, udid, taskIdx/*: this.currAiBoxIdx*/, device, ai, status }
        }

        this.updateAccountPortal('aibox-delete')
        this.showPortalAiBoxDelete = true
      } catch (err) {
        console.error(`onShowDeleteAiBoxTask fail.`, err)
      }
    },

    // Portal Control
    onCloseTaskPortal() {
      this.updateAccountPortal('')
      this.showPortalAiBoxTask = false
      this.currAiBoxIdx = -1
    },
    async onCloseDeletePortal() {
      this.updateAccountPortal('')
      this.showPortalAiBoxDelete = false

      if (this.delPortal.target === euDelTarget.task) {
        await sleep(0)
        this.showPortalAiBoxTask = true
        this.updateAccountPortal('aibox-task')
      }
    },
    onScrollAiBox(aibox) {
      const newAiboxIdx = this.filteredAiBoxes.findIndex((_b) => _b.id === aibox.id)
      this.currAiBoxIdx = newAiboxIdx;
    },
    async onConfirmPortalAiBoxDelete(idx, aiboxUdid) {
      let currAiBox = this.filteredAiBoxes[this.currAiBoxIdx]
      this.showPortalAiBoxDelete = false

      if (this.delPortal.target === euDelTarget.aiBox && currAiBox) {
        console.log(`[onConfirmPortalAiBoxDelete] Confirm delete aibox #${idx}`)

        // Call API for delete aiBox, 需要先確認該 AiBox 沒有任務了才可以刪除這個 AiBox
        // => 直接在 DeleteBtn 上處理
        try {
          const res = await apiDeleteAiBox(currAiBox.id)
          // console.log(`[onConfirmPortalAiBoxDelete] res:`, res)
          if (!apiCheckStatus(res)) throw res
          this.$notify({
            type: 'success',
            title: this.$t('setting_aibox_delete_notify_title'),
            message: this.$t('setting_aibox_delete_pass'),
          })
        } catch (err) {
          this.$notify({
            type: 'error',
            title: this.$t('setting_aibox_delete_notify_title'),
            message: this.$t('setting_aibox_delete_fail'),
          })
        } finally {
          this.onCloseDeletePortal('delete-aiBox')
        }
        this.onCloseDeletePortal('delete-aiBox')
      } else {
        console.log(`[onConfirmPortalAiBoxDelete] Confirm delete task #${idx} ${aiboxUdid}`)
        try {
          currAiBox = this.filteredAiBoxes.find((_b) => _b.udid === aiboxUdid)
          if (!currAiBox) return
          // console.log(`[onConfirmPortalAiBoxDelete] currAiBox:`, currAiBox)

          const currTask = currAiBox.tasks[idx]
          if (!currTask) return
          // console.log(`[onConfirmPortalAiBoxDelete] currTask:`, currTask)

          const res = await apiDeleteAiBoxTask(currTask.id)

          if (!apiCheckStatus(res)) throw res

          this.$notify({
            title: this.$t('setting_aibox_task_delete_notify_title'),
            message: this.$t('setting_aibox_task_delete_pass'),
            type: 'success',
          })

          await this.getAiBoxes()
          await this.getUserList() // 查詢 task 使用
        } catch (err) {
          console.error(`onConfirmPortalAiBoxDelete fail.`, err)
          // apiErrorMsg(err)
          this.$notify({
            title: this.$t('setting_aibox_task_delete_notify_title'),
            message: this.$t('setting_aibox_task_delete_fail'),
            type: 'error',
          })
        } finally {
          this.showPortalAiBoxTask = true
          this.updateAccountPortal('aibox-task')
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.aibox-mgr-table {
  position: relative;
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: $color_4A5C78;
  border-radius: 2px 2px 0 0;
}
.header .col {
  display: flex;
  align-items: center;
  width: calc(100% / 12);
  padding: 0.1rem 1rem;
  font-size: 1rem; // 17px;
  text-align: center;
  // background-color: #00f;
}

.col .col-content {
  display: flex;
  align-items: center;
  // background-color: #f0f;
}
.header .col {
  display: flex;
  align-items: center;
  .name {
    // padding-right: 0.7rem;
  }
  img.sort {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
    transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);

    &.sort-asc {
      @include filter_FFC600;
      transform: rotate(180deg);
    }

    &.sort-desc {
      @include filter_FFC600;
    }
  }
}
.header .delete {
  cursor: unset;
  width: 3%;
}
.content {
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 0 0 3px 3px;
  overflow: auto;

  background-color: #151b35;
  // background: #f00;
}
.content-body {
  width: 100%;
  height: 100%;

  // background: #00f;
  // background-color: $color_4A5C78;
}
.content-body .row {
  display: flex;
  height: 116px;
  width: 100%;
  // font-size: 1rem;
  padding: 0 0 1px;
  background: $color_4A5C78;

  // background-color: rgb(255, 187, 0);
}
.row-block {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  // background: #2F3B56;
  background-color: $color_151B35;
}
.row-block:hover,
.row-block:hover + .delete {
  background-color: rgba(40, 41, 66, 0.2);
  cursor: pointer;
}
.abnormal,
.abnormal:hover
// .abnormal:hover + .delete
{
  background-color: rgba(249, 65, 68, 0.2);
}
.abnormal:hover {
  cursor: pointer;
}
.row-col {
  display: flex;
  padding: 0.5rem 1rem;
  // font-size: 17px;
  height: 100%;
  width: calc(100% / 12);
  align-items: center;
  // background-color: rgb(150, 4, 254);

  button {
    cursor: unset;
    width: 3%;
    &:disabled {
      @include disabled;
    }
  }
}
.udid {
  display: inline;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.state div {
  margin-left: 0.3rem;
}
.state-icon {
  height: 24px;
  width: 24px;
}
.recognition {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recognition div {
  display: flex;
}
.recognition div img {
  padding-right: 0.5rem;
}
.recognition div div {
  padding-right: 0.5rem;
}
.row-col .ago {
  // font-size: 20px;
  color: #8cc24d;
}
.row .delete {
  // cursor: pointer;
  width: 3%;
}
.row .delete.abnormal {
  // background: url('../../../assets/icons/TrashCan.svg') 50% 50% no-repeat , rgba(249, 65, 68, .2);
  // cursor: pointer;
  background-color: unset;
}

</style>
