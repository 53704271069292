<template>
  <portal to="aibox-delete">
    <div class="delete-aibox-modal" @click="onClose"></div>
    <div class="account-message-box">
      <div class="header">
        <img src="@/assets/icons/warn-icon.svg" alt="" />
        <div>{{ title }}</div>
      </div>
      <div class="content">
        <div class="content-title">{{ content }}</div>
        <div class="sub-content" v-if="portal.target !== euDelTarget.task">
          <div class="sub-content-item" v-for="(key, idx) in Object.keys(portal.data).filter((key) => key != 'aiBoxIdx')"
            :key="`${key}${idx}`"
          >
            <div>{{ $t(`setting_aibox_table_${key}`) }}</div>
            <div>{{ ':' }}</div>
            <div>{{ portal.data[key] }}</div>
          </div>
        </div>
        <div class="sub-content task" v-else>
          <div class="task-block">
            <div class="header">
              <div class="col">
                <div>{{ $t('setting_aibox_table_name') }}</div>
              </div>
              <div class="col">
                <div>{{ $t('setting_aibox_table_udid') }}</div>
              </div>
              <div class="col">
                <div>{{ $t('setting_aibox_task_device') }}</div>
              </div>
              <div class="col">
                <div>{{ $t('setting_aibox_task_recognition_type') }}</div>
              </div>
              <div class="col">
                <div>{{ $t('setting_aibox_task_recognition_state') }}</div>
              </div>
            </div>
            <div class="body">
              <div class="col">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="portal.data.name"
                >
                  <div class="name">{{ portal.data.name }}</div>
                </el-tooltip>
              </div>
              <div class="col">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="portal.data.udid"
                >
                  <div class="udid">{{ portal.data.udid }}</div>
                </el-tooltip>
              </div>
              <div class="col">
                <el-tooltip
                  popper-class="el-tooltip"
                  effect="dark"
                  v-delTabIndex
                  placement="bottom"
                  :visible-arrow="false"
                  :content="portal.data.device"
                >
                  <div class="device">{{ portal.data.device }}</div>
                </el-tooltip>
              </div>
              <div class="col type">
                <img v-if="portal.data.ai === 'lpr'" src="@/assets/icons/car.svg" />
                <img v-else-if="portal.data.ai === 'or'" src="@/assets/icons/object.svg" />
                <!-- <img v-else-if="portal.data.ai === 'fr'" src="@/assets/icons/object.svg" /> -->
                <div>{{ $t(`setting_aibox_type_${portal.data.ai}`) }}</div>
              </div>
              <div class="col">
                <div class="state">
                <img v-if="portal.data.status === 0" src="@/assets/icons/success-check.svg"/>
                <img v-else src="@/assets/icons/warn-icon.svg" />
                <div>{{ $t(`setting_aibox_task_status_${portal.data.status}`) }}</div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btns" :style="{'padding-top': (portal.target !== euDelTarget.task) ? '12px' : '60px'}">
          <div class="btn cancel" @click="onCancel">{{ $t('cancel') }}</div>
          <div class="btn delete" @click="onConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { euDelTarget } from '@/components/SystemSetting/aiBoxMgr/Table.vue'


export default {
  name: 'PortalAiBocDelete',
  props: ['title', 'content', 'portal'], // portal: {target, data: {}}
  data() {
    return {
      euDelTarget,
    }
  },
  // computed: {},
  methods: {
    onClose() {
      this.$emit('close')
    },
    onConfirm() {
      const idx = (this.portal.target === euDelTarget.task) ? this.portal.data.taskIdx : this.portal.data.aiBoxIdx
      const udid = (this.portal.target === euDelTarget.task) ? this.portal.data.udid : undefined

      this.$emit('confirm', idx, udid)
    },
    onCancel() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.delete-aibox-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.account-message-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 602px; */ /* 因為 刪除辨識盒 & 刪除辨識盒任務 共用畫面, 所以不能設定 width */
  z-index: 3;
}

.account-message-box > .header {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 2rem;
  border-radius: 23px 23px 0px 0px;
  background: #4a5c78;
  color: #ffffff;
  font-weight: 300;
}

.account-message-box > .header img {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 32px 32px;
  border-radius: 0px 0px 23px 23px;
  border: 1px solid #4a5c78;
  border-top: 0;
  color: #ffffff;
  background: #282942;
  text-align: center;

  /* background-color: #00f; */
}

.content .content-title {
  // font-size: 24px;
  font-weight: 300;
  padding-bottom: 15px;

  /* background-color: #f00; */
}

.content .sub-content {
  display: flex;
  flex-direction: column;
  // font-size: 17px;
  padding: 6px 0;

  /* background-color: #f0f; */
}
.content .sub-content .sub-content-item {
  display: flex;
  justify-content: center;
}
.sub-content-item div {
  padding: 0 0.25rem;
}

.content .sub-content.task {
  padding-top: calc(32px - 15px);
}

.content .sub-content .task-block {
  padding: 1px 0;

  // font-size: 24px;
}
.task-block .header {
  display: flex;
  justify-content: space-around;
  padding: 0 32px;
  border-bottom: 1px solid #6e7d93;
  // font-size: 15px;

  /* background-color: #00f; */
}
.task-block .header .col {
  width: calc(100% / 5);
  padding-right: 56px;
  text-align: start;

  /* background-color: #f80; */
}
.task-block .header .col:last-child {
  width: 25%;
}
.task-block .body {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 8px 32px 0;

  /* background-color: #0f0; */
}
.body .col {
  display: flex;
  align-items: center;

  width: calc(100% / 5);
  padding-right: 56px;
  // font-size: 17px;

  /* background-color: #f0f; */
}
.body .col:last-child {
  width: 25%;
}
.body .col div {
  // font-size: 17px;
}
.body .col .name,
.body .col .udid,
.body .col .device {
  display: inline;
  width: 100%;
  position: relative;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.body .col img {
  height: 24px;
  width: 24px;
  margin: auto 4px;
}
.body .col .state {
  display: flex;
}

.btns {
  display: flex;
  justify-content: center;
  padding-top: 12px;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  // font-size: 17px;
  // font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

.cancel {
  border: 1px solid #ffffff;
  background: #282942;
  color: #ffffff;
  margin-right: 32px;
}

.cancel:hover {
  background: #3d3f55;
}

.delete {
  border: 1px solid #f94144;
  background-color: #f94144;
  color: #ffffff;
}

.delete:hover {
  border: 1px solid #f95052;
  background-color: #f95052;
}

// @media screen and (min-width: 1280px) {
//   .task-block .header {
//     font-size: 22px;
//   }
//   .content .content-title {
//     font-size: 32px;
//   }
//   .task-block .header {
//     font-size: 20px;
//   }
//   .content .sub-content,
//   .task-block .body,
//   .body .col div,
//   .btn {
//     font-size: 24px;
//   }
// }
</style>
