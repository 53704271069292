<template>
  <button class="magnifier-search-btn" :disabled="disabled" @click.stop="$emit('click')">
    <img v-if="!active" id="magnifier" src="@/assets/icons/search.svg"/>
    <div v-else class="searching"></div>
  </button>
</template>

<script>
export default {
  name: 'MagnifierSearchBtn',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
$BtnH: px2rem(36);
$BtnW: px2rem(80);
$ImgSize: px2rem(20);
* {
  box-sizing: border-box;
}
.magnifier-search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  height: $BtnH;
  width: $BtnW;
  background-color: $color_FFC600;
  img {
    height: $ImgSize;
    width: $ImgSize;
    @include filter_282942;
  }
  &:hover {
    background-color: $color_FFD133;
  }

  &:disabled {
    @include disabled;
  }

  .searching {
    position: relative;
    color: inherit;
    display: inline-block;
    box-sizing: content-box;
    @include loading(1.25rem, $color_282942);
  }
}
</style>
