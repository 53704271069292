<template>
  <div class="aibox-mgr-tail">
    <div class="page-sum">
      <div class="sub-page-count">
        <div>{{ $t('setting_aibox_page_start') }}</div>
        <div>{{ firstIdx }}</div>
        <div>-</div>
        <div>{{ lastIndex }}</div>
        <div>{{ $t('setting_aibox_page_end') }}</div>
      </div>
      <div class="pipe">|</div>
      <div class="sub-page-count">
        <div>{{ $t('setting_aibox_page_total') }}</div>
        <div>:</div>
        <div>{{ filteredAiBoxes.length }}</div>
        <div>{{ $t('setting_aibox_page_end') }}</div>
      </div>
      <div class="pipe">|</div>
      <!-- <div class="sub-page-count">
        <div>{{ $t('setting_aibox_total_of') }}</div>
        <div>{{ aiBoxes.length }}</div>
        <div>{{ $t('setting_aibox_of_records') }}</div>
      </div> -->
      <div class="sub-page-count">
        (
        <div class="desc">{{ $t('setting_aibox_top_count') }}</div>
        <div>:</div>
        <div class="value">{{ aiBoxCount }}</div>
        <div class="pipe">|</div>
        <div class="desc">{{ $t('setting_aibox_top_abnormal_count') }}</div>
        <div>:</div>
        <div class="value">{{ aiBoxAbnormalCount }}</div>
        )
      </div>
    </div>
    <div class="page-active">
      <div class="page-scroll">
        <div
          class="arrow left"
          :class="{ 'disable-arrow': realPages <= 1 }"
          @click="onArrow(-1)"
        ></div>
        <div
          class="pages"
          v-for="page in pageLoop"
          :key="page"
          :class="{ selected: pageIdx === page }"
          @click="toPage(page)"
        >
          {{ page + 1 }}
        </div>
        <div
          class="arrow right"
          :class="{ 'disable-arrow': realPages <= 1 }"
          @click="onArrow(1)"
        ></div>
      </div>
      <div class="assign-page">
        <div>{{ $t('setting_aibox_goto_page') }}</div>
        <input
          :value="currPage"
          type="text"
          :class="{ warning: !validateGoTo }"
          @focus="onPageFocus"
          @input="onPageInput"
          @blur="onPageBlur"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AiBoxMgrTail',
  // components: {  },
  data() {
    return {
      maxPageLoopLen: 5,
      // pageLoop: {start: 1, end: 5},
      pageIdx: 0
    }
  },
  mounted() {},
  computed: {
    ...mapState('setting/aiboxMgr', [
      'aiBoxes',
      'filteredAiBoxes',
      'currPage',

      'aiBoxFilterText',
      'ipFilterText'
    ]),
    ...mapGetters('setting/aiboxMgr', [
      'filteredAiBoxes',
      'fullPages',
      'pages'
    ]),
    realPages() {
      if (!this.aiBoxFilterText && !this.ipFilterText) {
        return this.fullPages
      }
      return this.pages
    },
    pageLoop() {
      return Array.from(Array(this.realPages), (v, k) => k)
    },
    firstIdx() {
      const len = this.filteredAiBoxes.length
      if (len > 0) {
        return (
          this.aiBoxes.findIndex(
            (box) => box.id === this.filteredAiBoxes[0].id
          ) + 1
        )
      }
      return 1
    },
    lastIndex() {
      const len = this.filteredAiBoxes.length
      if (len > 0) {
        return (
          this.aiBoxes.findIndex(
            (box) => box.id === this.filteredAiBoxes[len - 1].id
          ) + 1
        )
      }
      return this.aiBoxes.length
    },
    validateGoTo() {
      const { fullPages, pages, pageIdx } = this
      const ret1 = fullPages === 0
      const ret2 = pages > 0 && pageIdx >= 0 && pageIdx <= this.realPages - 1

      return ret1 || ret2
    },
    aiBoxCount() {
      return this.aiBoxes.length
    },
    aiBoxAbnormalCount() {
      return this.aiBoxes.filter(
        (aiBox) => aiBox.tasks.filter((task) => task.status !== 0).length > 0
      ).length
    }
  },
  watch: {
    pageIdx(nVal /*,oVal*/) {
      const currPage = nVal < 0 ? '1' : `${nVal + 1}`
      this.updateCurrPage(currPage)
      this.pageIdx = parseInt(currPage, 10) - 1
    }
  },
  methods: {
    ...mapMutations('userinfo/aibox', ['updateCurrPage']),
    onArrow(distance) {
      if (this.realPages < 1) {
        return
      }

      this.pageIdx =
        this.pageIdx >= 0
          ? (this.pageIdx + distance + this.realPages) % this.realPages
          : 0
    },
    toPage(num) {
      this.pageIdx = num
    },
    onPageInput(event) {
      try {
        const currPage = event.target.value.trim()

        this.pageIdx = isNaN(currPage) ? -1 : parseInt(currPage, 10) - 1
      } catch (err) {
        console.error(`[onPageInput] err:`, err.message)
      }
      event.target.setSelectionRange(0, event.target.value.length)
    },
    onPageFocus(event) {
      event.target.setSelectionRange(0, event.target.value.length)
    },
    onPageBlur(event) {
      event.target.setSelectionRange(
        event.target.value.length - 1,
        event.target.value.length
      )
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.aibox-mgr-tail {
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1rem;
  padding: 12px 0;

  // background-color: rgb(189, 172, 43);
}
.page-sum {
  display: flex;
  // position: absolute;
  // left: 0;
  flex-wrap: wrap;
  // flex: 0;
  // background-color: orange;
}
.pipe {
  padding: 0 0.7rem;
}
.sub-page-count {
  display: flex;
}
.sub-page-count div {
  padding: 0 0.1rem;
}

.page-active {
  display: flex;
  // font-size: 17px;
  align-items: center;
  flex: 1;
  // padding: 1rem;
  // background-color: purple;
}
.page-scroll {
  display: flex;
  // font-size: 17px;
  align-items: center;
  // font-weight: 500;

  // background-color: #f0f;
}
// 等同 .pages {}
.page-scroll div {
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 0 2px;
}
.arrow {
  padding: 8px;
  margin: 0 16px !important;
  background: url('../../../assets/icons/page_left_arrow.svg') 50% 50% no-repeat;
  background-size: contain;
}

.arrow.right {
  background: url('../../../assets/icons/page_right_arrow.svg') 50% 50%
    no-repeat;
  background-size: contain;
}

.disable-arrow {
  cursor: unset !important;
  opacity: 0.5;
}

.page-scroll .pages.selected {
  border-radius: 50%;
  border: 1px solid #6e7d93;
  background-color: #6e7d93;
}

.page-scroll .pages:nth-child(2),
.page-scroll .pages:nth-last-child(2) {
  border-radius: 50%;
  border: 1px solid #fff;
}

.assign-page {
  display: flex;
  align-items: baseline;
}
.assign-page div {
  padding: 0 1rem;
}
.assign-page input {
  background: transparent;
  border: 1px solid #6e7d93;
  border-radius: 4px;
  // font-size: 0.84375rem;
  color: #fff;
  padding: 0 1rem;
  width: 48px;
  height: 24px;
  outline: none;
}
.assign-page input.warning {
  border: 2px solid #f94144;
}

// @media screen and (min-width: 1280px) {
//   .aibox-mgr-tail,
//   .page-active,
//   .page-scroll {
//     font-size: 24px;
//   }

//   .assign-page input {
//     font-size: 1.125rem;
//   }

//   // 等同 .pages {}
//   .page-scroll div {
//     height: 40px;
//     width: 40px;
//   }
// }
</style>
